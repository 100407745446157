// customize Toastify as indicated in docs
.Toastify {
  &__toast {
    border-radius: 10px;
    &--error {
      background: #da1640;
    }
    &--warning {
      background: #ffb82f;
    }
    &--success {
      background: #5cc78b;
    }
  }

  &__toast-container {
    width: auto;
  }

  // custom classes
  &__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
